<template>
  <div>
    <div>
      <div>
        <ValidationObserver ref="formCreateAlarmCentral">
          <form>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="name" type="text" field_name="Namn" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="phone" type="text" field_name="Telefon" rules="required" />
              </div>
            </div>

            <div class="flex flex-wrap">
              <div class="w-full px-2 py-2">
                <BaseInput v-model="email" type="text" field_name="Email" rules="required" />
                <span class="text-xs italic">Ange flera e separerade med kommatecken</span>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>

            <div class="flex flex-wrap">
              <div class="w-full flex-col px-2 py-2">
                <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">SMS <b class="text-xxs italic font-normal font-sans text-secondary-500">(Fullständigt mobilnummer med land/riktnummer)</b></div>
                <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in report_phones" :key="i">
                  <div class="w-3/12 pr-2">
                    <BaseInput v-model="n.msisdn" @keypress="isNumber($event)" maxlength="15" type="text" :field_name="'SMS Nummer ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-3/12 pr-2">
                    <BaseSelect v-model="n.device_identifier" :options="deviceIdentifiers" nameSelector="name" optionSelector="id" :field_name="'Enhetsidentifierare ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-3/12 pr-2">
                    <BaseSelect v-model="n.report_format" :options="reportFormats" nameSelector="name" optionSelector="id" :field_name="'Rapportformat ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-2/12 flex flex-col pr-2 pt-2">
                    <template v-for="s in additionalSettings">
                      <div class="w-full" v-bind:key="s.id">
                        <label class="inline-flex items-center mt-2">
                          <input v-model="n.additional_settings" :value="s.id" type="checkbox" class="form-checkbox h-3 w-3" />
                          <span class="ml-2 text-gray-600 text-sm font-sans font-semibold">{{ s.name }}</span>
                        </label>
                      </div>
                    </template>
                  </div>
                  <div class="w-1/12 pt-8 text-center">
                    <button class="text-red-500 pl-2" @click.prevent="removeSMSInput(i)"><BaseIcon icon="minus-circle" /></button>
                  </div>
                </div>
                <div class="w-full">
                  <button class="btn-blue-outline mt-2" @click.prevent="addSMSInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
                </div>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>

            <div class="flex flex-wrap">
              <div class="w-full flex-col px-2 py-2">
                <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">TCP</div>
                <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in report_ips" :key="i">
                  <div class="w-3/12 pr-2">
                    <BaseInput v-model="n.ip" maxlength="15" type="text" :field_name="'IP-adress ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-2/12 pr-2">
                    <BaseInput v-model="n.port" @keypress="isNumber($event)" min="0" max="65535" placeholder="Port" type="number" :field_name="'Port ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-3/12 pr-2">
                    <BaseSelect v-model="n.device_identifier" :options="deviceIdentifiers" nameSelector="name" optionSelector="id" :field_name="'Enhetsidentifierare TCP ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-3/12 pr-2">
                    <BaseSelect v-model="n.report_format" :options="reportFormats" nameSelector="name" optionSelector="id" :field_name="'Rapportformat TCP ' + (i + 1)" rules="required" />
                  </div>
                  <div class="w-1/12 pt-8 text-center">
                    <button class="text-red-500 pl-2" @click.prevent="removeIPInput(i)"><BaseIcon icon="minus-circle" /></button>
                  </div>
                </div>
                <div class="w-full">
                  <button class="btn-blue-outline mt-2" @click.prevent="addIPInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
                </div>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>

            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 flex-col px-2 py-2">
                <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">Rapporttyp</div>
                <div class="w-full" v-for="r in reportTypes" :key="r.id">
                  <label class="inline-flex items-center mt-3">
                    <input v-model="report_types" :value="r.id" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">{{ r.name }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>

            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <label class="inline-flex items-center mt-3"> <input v-model="periodic_report.active" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">Periodic report</span> </label>
              </div>
              <div v-if="periodic_report && periodic_report.active" class="w-full md:w-1/3 px-2 py-2">
                <BaseSelect v-model="periodic_report.trigger" :options="periodicReportTriggers" nameSelector="name" optionSelector="id" field_name="Trigger" rules="required" />
              </div>
              <div v-if="periodic_report && periodic_report.active && periodic_report.trigger == 'critical'" class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="periodic_report.time_limit" @keypress="isNumber($event)" min="0" max="120" placeholder="Minutes" type="number" field_name="Time" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full px-2">
                <div class="clearfix">
                  <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createAlarmCentral()">
                    Spara
                    <BaseIcon icon="arrow-right" class="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return `Admin - Ny larmcentral`;
  },

  data() {
    return {
      reportTypes: [
        { name: "Skarptlarm", id: "push_button" },
        { name: "Man down", id: "man_down" },
        { name: "Timerlarm", id: "timer_alarm_stop" },
        { name: "Internlarm", id: "call_b" },
      ],
      deviceIdentifiers: [
        { name: "IMEI", id: "imei_number" },
        { name: "Telematiknummer", id: "phone" },
        { name: "Larmnummer", id: "alarm_number" },
      ],
      reportFormats: [
        { name: "OVLS B&M", id: "ovls_bm" },
        { name: "SRT#", id: "srt" },
        { name: "Short XML", id: "shortxml" },
        { name: "Signal Protocol", id: "signal_proto" },
        { name: "OVLS Tempest", id: "ovls_tempest" },
        { name: "OVLS Avarn", id: "ovls_avarn" },
        { name: "User defined", id: "user_defined" },
      ],
      additionalSettings: [
        { name: "Google map link", id: "gmap_link" },
        { name: "RF/SecurPos", id: "tag_address" },
      ],
      periodicReportTriggers: [
        { name: "Alltid", id: "always" },
        { name: "Skarptlarm, Man down", id: "critical" },
      ],
      name: "",
      email: "",
      phone: "",
      report_phones: [],
      report_ips: [],
      report_types: [],
      periodic_report: {
        active: false,
        trigger: "critical",
        time_limit: 60,
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    createAlarmCentral() {
      this.$refs.formCreateAlarmCentral.validate().then((success) => {
        if (!success) {
          return;
        }

        // validate IP address
        let isValid = this.validateIPaddresses(this.report_ips);
        if (isValid == false) {
          this.handleError(null, "IP-adress är ogiltig");
          return;
        }

        let emails = this.email.replace(/\s+/g, "").split(",");
        let emails_filtered = [];
        emails.forEach((e) => {
          if (this.validateEmail(e)) emails_filtered.push(e);
        });
        emails_filtered = emails_filtered.join();

        let data = {
          name: this.name,
          email: emails_filtered,
          phone: this.phone,
          report_phones: this.report_phones,
          report_ips: this.report_ips,
          report_types: this.report_types,
          periodic_report: this.periodic_report,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/alarm-central/`, data)
          .then(() => {
            // eslint-disable-line
            this.$router.push({ name: "alarm_central" });
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    validateEmail: function(e) {
      //eslint-disable-next-line
      let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return emailRegex.test(e);
    },

    validateIPaddresses(ipAddresses) {
      let ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      let isValid = true;
      ipAddresses.forEach((a) => {
        if (!ipRegex.test(a.ip)) isValid = false;
      });

      return isValid;
    },

    addSMSInput() {
      this.report_phones.push({ msisdn: "", device_identifier: "", report_format: "", additional_settings: [] });
    },

    removeSMSInput(i) {
      this.report_phones.splice(i, 1);
    },

    addIPInput() {
      this.report_ips.push({ ip: "", port: "", device_identifier: "", report_format: "" });
    },

    removeIPInput(i) {
      this.report_ips.splice(i, 1);
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  created() {
    this.setPageTitle("Admin - Ny larmcentral", "create_alarm_central");
  },
};
</script>
